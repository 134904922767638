var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-section"
  }, [_c('div', {
    staticClass: "mt-0"
  }, [_c('advertisement-top-banner-text'), _vm.$options.components['advertisement-loading'] ? [_vm.isLoading ? _c('advertisement-loading') : _vm._e()] : _vm._e(), _c('div', {
    staticClass: "container mt-3"
  }, [_vm.productData ? _c('b-row', {
    staticClass: "product-main my-3"
  }, [_c('b-col', {
    staticClass: "product-border product-images",
    attrs: {
      "md": "5"
    }
  }, [_vm.productData['Discount'] || _vm.productData['Discount(%)'] ? _c('div', {
    staticClass: "show-variant"
  }, [_vm.productData['Discount'] ? _c('span', {
    staticClass: "variant-span"
  }, [_vm._v(" ₹" + _vm._s(_vm.productData["Discount"]) + " off ")]) : _vm._e(), _vm.productData['Discount(%)'] ? _c('span', {
    staticClass: "variant-span"
  }, [_vm._v(" " + _vm._s(_vm.productData["Discount(%)"]) + "% off ")]) : _vm._e()]) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, _vm._l(_vm.imagesArr, function (product, i) {
    var _ref;

    return _c('div', {
      key: i,
      staticClass: "productdetail-imgshort",
      on: {
        "mouseover": function mouseover($event) {
          return _vm.setActiveBox(i);
        }
      }
    }, [_c('b-img', {
      staticClass: "product-thumb-image my-3",
      class: [(_ref = {}, _ref[i === _vm.activeBox ? 'box-hovered' : 'box-common'] = true, _ref)],
      attrs: {
        "src": product,
        "center": "",
        "height": "100",
        "width": "100",
        "id": "product-img-1"
      }
    })], 1);
  }), 0), _c('b-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('div', {
    staticClass: "align-items-center product-main-image-column"
  }, [_c('b-img', {
    staticClass: "product-main-image my-3",
    attrs: {
      "id": "product-img-1",
      "src": _vm.imagesArr[_vm.activeBox],
      "alt": "",
      "height": "320",
      "width": "260"
    }
  })], 1)]), _c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column justify-content-between align-items-center product-icons"
  }, [_c('div', {
    staticClass: "product-icon-circle pointer heart mb-2"
  }, [_vm.wishlistKey ? _c('b-icon', {
    attrs: {
      "size": "xl",
      "icon": "heart-fill"
    },
    on: {
      "click": function click($event) {
        return _vm.removeItem(_vm.productData);
      }
    }
  }) : _c('b-icon', {
    attrs: {
      "size": "xl",
      "icon": "heart"
    },
    on: {
      "click": function click($event) {
        return _vm.addToWishlist(_vm.productData);
      }
    }
  })], 1)])])], 1)], 1), _c('b-col', {
    staticClass: "product-info pr-0 pl-3",
    attrs: {
      "md": "7"
    }
  }, [_c('v-breadcrumbs', [_c('div', [_c('span', {
    staticClass: "product-address",
    on: {
      "click": function click($event) {
        return _vm.$router.push("".concat(_vm.items.href));
      }
    }
  }, [_vm._v(_vm._s(_vm.items.text))]), _c('v-icon', [_vm._v("mdi-chevron-right")]), _c('span', {
    staticClass: "product-address",
    on: {
      "click": function click($event) {
        return _vm.$router.push("/market/".concat(_vm.productData.Market));
      }
    }
  }, [_vm._v(_vm._s("".concat(_vm.productData.Market)))]), _c('v-icon', [_vm._v("mdi-chevron-right")]), _c('span', {
    staticClass: "text-no-href"
  }, [_vm._v(_vm._s("".concat(_vm.productData.Name)))])], 1)]), _vm.productData.Name || _vm.productData.Quantity ? _c('h1', [_vm._v(" " + _vm._s(_vm.productData.Name) + " " + _vm._s(_vm.productData.Quantity) + " ")]) : _vm._e(), _vm.productData.Brand ? _c('p', {
    staticClass: "product-location mb-0"
  }, [_vm._v(" " + _vm._s(_vm.productData.Brand) + " ")]) : _vm._e(), _c('div', {
    staticClass: "d-flex mt-3 justify-content-between justify-content-sm-between align-items-center product-price"
  }, [_vm.productData['Discount(%)'] ? _c('div', {
    staticClass: "d-flex price-del-width"
  }, [_c('del', {
    staticClass: "mdi mdi-currency-inr text-icon price mb-0 mr-3"
  }, [_vm._v(_vm._s(_vm.productData["Deal Price"]))]), _c('h1', {
    staticClass: "mdi mdi-currency-inr text-icon price mb-0 margin-left-8"
  }, [_vm._v(" " + _vm._s(_vm.productData.Price) + " ")])]) : _vm._e(), !_vm.productData['Discount'] && !_vm.productData['Discount(%)'] ? _c('h1', {
    staticClass: "mdi mdi-currency-inr text-icon price mb-0 product-price-mobile"
  }, [_vm._v(" " + _vm._s(_vm.productData.Price) + " ")]) : _vm._e(), _vm.isVariationAvailable ? _c('div', {
    attrs: {
      "id": "unit-button-box"
    }
  }, _vm._l(_vm.productVariation, function (variant) {
    return _c('button', {
      key: variant._id,
      class: {
        selectedUnit: variant.attributes.Quantity === _vm.productData.Quantity
      },
      on: {
        "click": function click($event) {
          return _vm.selectUnit(variant.attributes.Quantity);
        }
      }
    }, [_vm._v(" " + _vm._s(variant.attributes.Quantity) + " ")]);
  }), 0) : _vm._e(), _vm.productData.Availability && _vm.productData.Availability === 'OUT OF STOCK' ? _c('div', [_c('p', {
    staticClass: "text-danger font-weight-bold"
  }, [_vm._v("OUT OF STOCK")])]) : _c('div', {
    staticClass: "add-button add-button-mobile",
    attrs: {
      "id": "add-cart"
    }
  }, [_vm.$options.components['checkout-increment-decrement'] ? [_c('checkout-increment-decrement', {
    attrs: {
      "callCheckout": false,
      "cartItem": _vm.productData
    }
  })] : _vm._e()], 2)]), _c('div', {
    staticClass: "product-rate d-flex justify-content-between align-items-center justify-content-sm-between"
  }, [_c('h2', {
    staticClass: "mb-0"
  }, [_vm._v("Rate this Product:")]), _vm.productFeedback ? _c('div', {
    staticClass: "d-flex justify-content-between align-items-center mr-5"
  }, [_c('div', {
    staticClass: "product-stars"
  }, [_c('b-form-rating', {
    attrs: {
      "variant": "warning",
      "color": "#fd7e14",
      "value": _vm.ratingStarCount,
      "readonly": ""
    }
  })], 1), _c('h2', {
    staticClass: "mb-0"
  }, [_vm._v("(" + _vm._s(_vm.productFeedback.ratingCount || "0") + ")")])]) : _vm._e(), _c('div', {
    staticClass: "mr-4"
  }, [_c('h2', {
    staticClass: "write-review",
    on: {
      "click": function click($event) {
        return _vm.$bvModal.show('product-review');
      }
    }
  }, [_vm._v(" Write Review ")])])]), _c('b-modal', {
    attrs: {
      "id": "product-review",
      "size": "md",
      "centered": "",
      "title": "Review This Product",
      "hide-footer": ""
    }
  }, [_c('v-container', [_c('v-row', [_c('b-input-group', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-start"
  }, [_c('div', [_c('b-img', {
    staticClass: "product-main-image",
    attrs: {
      "id": "product-img-1",
      "src": _vm.imagesArr[_vm.activeBox],
      "alt": "",
      "height": "80",
      "width": "80"
    }
  })], 1), _c('div', {
    staticClass: "mt-3 ml-3"
  }, [_c('h4', {
    staticStyle: {
      "color": "#fd7e14"
    }
  }, [_vm._v(_vm._s(_vm.productData.Name))])])])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', [_c('h6', {
    staticClass: "mb-0"
  }, [_vm._v("Rate This Product")]), _c('b-form-rating', {
    staticClass: "pl-0 pr-0",
    attrs: {
      "variant": "warning",
      "color": "#fd7e14",
      "inline": "",
      "size": "lg"
    },
    model: {
      value: _vm.ratingStar,
      callback: function callback($$v) {
        _vm.ratingStar = $$v;
      },
      expression: "ratingStar"
    }
  })], 1)]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('h6', [_vm._v("Additional Comments")]), _c('div', {
    staticClass: "mb-3"
  }, [_c('b-form-textarea', {
    staticClass: "mt-3",
    staticStyle: {
      "background": "#f8f8f8"
    },
    attrs: {
      "placeholder": "Description",
      "rows": "5"
    },
    model: {
      value: _vm.feedbackComment,
      callback: function callback($$v) {
        _vm.feedbackComment = $$v;
      },
      expression: "feedbackComment"
    }
  })], 1)]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-center mt-2"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary mr-3 pr-5 pl-5",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$bvModal.hide('product-review');
      }
    }
  }, [_c('span', {
    staticClass: "button-text",
    on: {
      "click": _vm.clear
    }
  }, [_vm._v("Clear")])]), _c('button', {
    staticClass: "btn btn-primary ml-3",
    attrs: {
      "type": "button"
    }
  }, [_c('span', {
    staticClass: "button-text pr-5 pl-5",
    on: {
      "click": _vm.submitProductReview
    }
  }, [_vm._v("Save")])])])])], 1)], 1)], 1)], 1), _vm.productData.providerName ? _c('div', {
    staticClass: "d-flex"
  }, [_c('dt', {
    staticClass: "mr-1"
  }, [_vm._v("Provider :")]), _c('router-link', {
    attrs: {
      "to": {
        path: "/shopDetails/".concat(_vm.productData.provider, "/").concat(_vm.productData.providerName)
      }
    }
  }, [_vm._v(_vm._s(_vm.productData.providerName))])], 1) : _vm._e(), _c('div', {
    staticClass: "product-details"
  }, [_c('dt', [_vm._v("Product Details:")]), _vm.productData.Name ? _c('dd', [_vm._v(" Product Name : " + _vm._s(_vm.productData.Name) + " ")]) : _vm._e(), _vm.productData.City && _vm.productData.State ? _c('dd', [_vm._v(" City : " + _vm._s(_vm.productData.City) + " " + _vm._s(_vm.productData.State) + " ")]) : _vm._e(), _vm.productData.Description ? _c('dd', [_vm._v(" Description : " + _vm._s(_vm.productData.Description) + " ")]) : _vm._e()])], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('h4', [_vm._v("Rating & Reviews")])]), _c('div', [_c('button', {
    staticClass: "btn btn-outline-primary"
  }, [_c('div', {
    staticClass: "write-review-bottom pr-2 pl-2",
    on: {
      "click": function click($event) {
        return _vm.$bvModal.show('product-review');
      }
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("Write Review")])])])])])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_vm.productFeedback ? _c('div', _vm._l(_vm.productFeedback.ratings, function (rating) {
    return _c('div', {
      key: rating.userId,
      staticClass: "rating-reviews"
    }, [_c('div', {
      staticClass: "mb-4"
    }, [_c('div', {
      staticClass: "d-flex flex-row align-items-center"
    }, [_c('div', {
      staticClass: "d-flex flex-row star-count pl-1 pr-1"
    }, [_c('div', {
      staticClass: "mr-2"
    }, [_c('b-icon', {
      staticStyle: {
        "color": "#fd7e14"
      },
      attrs: {
        "icon": "star-fill"
      }
    })], 1), _c('div', [_c('p', {
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(rating.stars))])])]), _c('div', {
      staticClass: "ml-4"
    }, [_c('h6', [_vm._v(_vm._s(rating.givenBy.name.first))])])]), _c('div', {
      staticClass: "review-comment mt-2"
    }, [_c('p', [_vm._v(_vm._s(rating.feedbackComment))])])]), _c('hr')]);
  }), 0) : _c('div', [_c('p', [_vm._v("No reviews yet!")])])])], 1)], 1), _vm.relatedItems && _vm.relatedItems.length ? _c('sections-slider', {
    staticClass: "suggested-slider",
    attrs: {
      "list": _vm.relatedItems,
      "subTitle": "Related Products",
      "hasBackground": true,
      "showTitle": true,
      "hasButton": false,
      "withSlider": true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var listItem = _ref2.listItem;
        return [_c('div', {
          staticClass: "item-box"
        }, [_c('items-item', {
          staticClass: "suggester-div",
          attrs: {
            "itemNoBg": true,
            "product": listItem,
            "incrementDecrementKey": true
          }
        })], 1)];
      }
    }], null, false, 113096431)
  }) : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }